import { Navigate, useNavigate, useRoutes } from 'react-router-dom';

// import MainLayout from './layouts/main';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Login';
import Signup from './pages/Signup';
// import MainView from './pages/Main';
// import AnotherView from './pages/AnotherView';
import NotFound from './pages/Page404';

import AuthRequired from 'components/AuthRequired';
import AllComponents from 'pages/AllComponents';
import SubscriptionLayout from 'layouts/SubscriptionLayout';
import SubscriptionHome from 'pages/subscriptions/SubscriptionHome';
import IssuerLayout from 'layouts/IssuerLayout';
// import IssuerHome from 'pages/issuer/IssuerHome';
import SubscriptionForm from 'pages/subscriptions/SubscriptionForm';
// import TokenDashboard from 'pages/issuer/TokenDashboard';
import RequestForgotPassword from 'pages/RequestForgotPassword';
import ForgotPassword from 'pages/ForgotPassword';
// import CreateTokenReview from 'pages/issuer/CreateTokenReview';
// import CreateAllotment from 'pages/issuer/CreateAllotment';
import MaintainWhitelist from 'pages/issuer/MaintainWhitelist';
import InvestorDashboard from 'pages/subscriptions/InvestorDashboard';
import CreateToken from 'pages/issuer/CreateToken';
// import BTLEditToken from 'pages/issuer/BTLEditToken';
import ChangePassword from 'pages/ChangePassword';
import Home from 'pages/Home';
import ViewToken from 'pages/subscriptions/ViewToken';
import AddOrganization from 'pages/issuer/AddOrganization';
import { resourcePersistence } from 'persistence';
import PaymentTxn from 'pages/issuer/PaymentTxn';
import IssuerInsurerMap from 'pages/issuer/IssuerInsurerMap';
import AllNotifications from 'pages/AllNotifications';
import AddIssueType from 'pages/documentManagement/AddIssueType';
import AddDocType from 'pages/documentManagement/AddDocType';
import AddDocSubType from 'pages/documentManagement/AddDocSubType';
import AssociateDocType from 'pages/documentManagement/AssociateDocType';
import PoolPerfomanaceDashboard from 'pages/masterData/liquidityPool/PoolPerfomanaceDashboard';
import TokenNotifications from 'pages/TokenNotifications';
import BTLTokenDashboard from 'pages/issuer/BTLTokenDashboard';
import CustomerDetails from 'components/issuerDashboard/CustomerDetails';
import BTLViewAllotment from 'pages/issuer/BTLViewAllotment';
import BTLViewRedemption from 'pages/issuer/BTLViewRedemption';
import BTLSubscriptions from 'pages/issuer/BTLSubscriptions';
import BTLEditToken from 'pages/issuer/BTLEditToken';
import BTLFeesCharges from 'pages/issuer/BTLFeesCharges';
import BTLReports from 'pages/issuer/BTLReports';
import Rollover from 'components/issuerDashboard/Rollover';
import BettingEvents from 'pages/issuer/BettingEvents';

const Router = () => {
  const navigate = useNavigate();
  // For Development, update the the version manually in Code not in env file or AWS environment variables
  // Update Only {patch-version} and only on Deployment
  const version = process.env.REACT_APP_APP_VERSION || '1.0.5';
  console.log('APP VERSION: (old, new)', resourcePersistence.get('VERSION'), version);
  if (resourcePersistence.get('VERSION') && resourcePersistence.get('VERSION') !== version) {
    localStorage.clear();
    resourcePersistence.set('VERSION', version);
    console.log('UPDATED VERSION REFRESH: ', resourcePersistence.get('VERSION'), version);
    navigate('/');
  } else if (!resourcePersistence.get('VERSION')) {
    resourcePersistence.set('VERSION', version);
    console.log('NEW VERSION set');
  }

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'sign-up', element: <Signup /> },
        { path: 'request-forgot-password', element: <RequestForgotPassword /> },
        { path: 'forgot-password/:token', element: <ForgotPassword /> },
        { path: 'all', element: <AllComponents /> },
        { path: '404', element: <NotFound /> },
        // { path: 'multisig', element: <Navigate to="/home" /> },
        // { path: 'multisig/subscription', element: <Navigate to="/subscription" /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/subscription',
      element: (
        <AuthRequired>
          <SubscriptionLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/', element: <SubscriptionHome /> },
        { path: '/form', element: <SubscriptionForm /> },
        { path: '/form/:tokenAddress', element: <SubscriptionForm /> },
        { path: '/investor-dashboard', element: <InvestorDashboard /> },
        { path: '/view-token', element: <ViewToken /> },
        { path: '/notifications', element: <AllNotifications /> },
        { path: '/change-password', element: <ChangePassword /> }
      ]
    },
    {
      path: '/home',
      element: (
        <AuthRequired>
          <IssuerLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/', element: <Home /> },
        { path: '/token-dashboard', element: <BTLTokenDashboard /> },
        {
          path: '/token-dashboard',
          children: [
            // { path: '/manage-criteria', element: <ManageCriteria /> },
            { path: '/maintain-whitelist', element: <MaintainWhitelist /> },
            // { path: '/create-allotment', element: <CreateAllotment /> },
            { path: '/subscriptions', element: <BTLSubscriptions /> },
            { path: '/reports', element: <BTLReports /> },
            { path: '/view-allotment', element: <BTLViewAllotment /> },
            { path: '/view-redemption', element: <BTLViewRedemption /> },
            { path: '/fees-charges', element: <BTLFeesCharges /> },
            { path: '/customer-details', element: <CustomerDetails /> },

            // { path: '/review', element: <CreateTokenReview /> },
            { path: '/edit-token', element: <BTLEditToken /> },
            // { path: '/edit-token', element: <EditToken /> },
            // { path: '/kyc-dashboard', element: <KYCDashboard /> },
            // { path: '/kyc-user', element: <KYCuser /> },
            // { path: '/escrow-settlement', element: <EscrowSettlement /> },
            // { path: '/doc-manage-dashboard', element: <DocMgtDashboard /> },
            // { path: '/doc-manage-user', element: <DocMgtUser /> },
            { path: '/token-notifications', element: <TokenNotifications /> },
            { path: '/payment-transactions', element: <PaymentTxn /> },
            { path: '/betting-events', element: <BettingEvents /> },
            { path: '/rollover', element: <Rollover /> }
          ]
        },
        { path: '/create-token', element: <CreateToken /> },
        // { path: '/admin-dashboard', element: <OwnerDashboard /> },
        { path: '/add-organization', element: <AddOrganization /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/issuer-insurer-map', element: <IssuerInsurerMap /> },
        { path: '/notifications', element: <AllNotifications /> },
        { path: '/add-issue-type', element: <AddIssueType /> },
        { path: '/add-doc-type', element: <AddDocType /> },
        { path: '/add-doc-sub-type', element: <AddDocSubType /> },
        { path: '/map-doc-types', element: <AssociateDocType /> },
        { path: '/performance-dashboard', element: <PoolPerfomanaceDashboard /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
};

export default Router;
