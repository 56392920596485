import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  FormLabel,
  TextField,
  Grid,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { useAppState, useHttpApi } from 'state';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import FocusError from 'components/FocusError';
import LoadingButton from 'theme/overrides/LoadingButton';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';

// Modal component to show Plaid account information
const RolloverModal = ({ rolloverInfomation, refetch }) => {
  const { throwErrorMessage } = useAppState();

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const { getAllInvestorAndRolloverStatus, createRolloverRecord } = useHttpApi();

  const { enqueueSnackbar } = useSnackbar();
  const proofDocument = useRef(null);
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4
  };

  const RolloverSchema = Yup.object().shape({
    investor_name: Yup.string(),
    rollover_proof_document: Yup.mixed().test(
      'type',
      'Only .pdf .doc .docx formats are accepted',
      (value) => {
        let allGood = false;

        if (value) {
          allGood = value && value.substring(value.lastIndexOf('.')) === '.pdf';
        } else {
          allGood = true;
        }
        return allGood;
      }
    ),
    is_proceed: Yup.boolean().oneOf([true], 'Please accept this to proceed')
  });

  useEffect(() => {
    if (rolloverInfomation) {
      formik.setFieldValue('investor_name', rolloverInfomation?.investor_name);
      formik.setFieldValue('current_token_name', rolloverInfomation?.current_token_name);
      formik.setFieldValue('current_holdings', rolloverInfomation?.current_holdings);
      formik.setFieldValue('invested_amount', rolloverInfomation?.invested_amount);
      formik.setFieldValue('investment_value', rolloverInfomation?.investment_value);
      formik.setFieldValue('profit_or_loss_amount', rolloverInfomation?.profit_or_loss_amount);
      formik.setFieldValue('current_token_price', rolloverInfomation?.current_token_price);
      formik.setFieldValue('settlement_tokens', rolloverInfomation?.settlement_tokens);
    }
  }, [rolloverInfomation]);
  const rolloverInitialValues = {
    investor_name: '',
    current_token_name:'',
    current_holdings: '',
    invested_amount: '',
    investment_value: '',
    profit_or_loss_amount: '',
    current_token_price: '',
    settlement_tokens: '',
    rollover_proof_document: '',
    is_proceed: false
  };
  const formik = useFormik({
    initialValues: rolloverInitialValues,
    validate: (values) => {
      const errors = {};

      if (!values.rollover_proof_document && rolloverInfomation?.profit_or_loss_amount > 0) {
        errors.rollover_proof_document = 'Rollover Documents is required.';
      }

      if (values.rollover_proof_document) {
        if (proofDocument && proofDocument.current.files[0].size > 20971520) {
          errors.rollover_proof_document = 'File size greater than 20MB';
        }
      }

      return errors;
    },
    validationSchema: RolloverSchema,
    onSubmit: async (data) => {
      setSubmitting(true);
      const rolloverProofDocument = proofDocument.current.files[0];

      if (rolloverProofDocument) {
        if (rolloverProofDocument.size > 20971520) {
          throw new Error('Upload Contract File less than 20MB', {
            variant: 'error'
          });
        }
        data.rollover_proof_document = rolloverProofDocument;
        formik.setFieldValue('rollover_proof_document', '');
      } else {
        delete data.rollover_proof_document;
      }
      data.investor_id = rolloverInfomation.investor_id;
      data.token_holdings_at_rollover = rolloverInfomation.current_holdings;
      data.invested_amount_at_rollover = rolloverInfomation.invested_amount;
      data.investment_value_at_rollover = rolloverInfomation.investment_value;
      data.additional_settled_token_qty = rolloverInfomation.settlement_tokens;
      data.token_value_at_rollover = rolloverInfomation.current_token_price;
      data.token_id = rolloverInfomation.current_token_id;

      delete data.current_token_price;
      delete data.current_holdings;
      delete data.invested_amount;
      delete data.investment_value;
      delete data.investment_value;
      delete data.settlement_tokens;
      console.log('Data', data);
      try {
        const res = await createRolloverRecord(data);
        console.log(res);
        enqueueSnackbar(res.message, { variant: 'success' });
        refetch();
        handleClose();
      } catch (e) {
        console.log(e);
        throwErrorMessage(e);
      }
      setSubmitting(false);
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Button
        variant="contained"
        sx={{ backgroundColor: `${rolloverInfomation?.is_rollover_complete && 'green'}` }}
        onClick={handleOpen}
      >
        {rolloverInfomation?.is_rollover_complete ? `Completed` : `Process`}
      </Button>

      <Modal
        BackdropProps={{
          timeout: 500,
          onClick: (e) => e.stopPropagation() // This prevents closing the modal by clicking on the backdrop
        }}
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box mt={2}>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Box sx={modalStyle} mt={2}>
              <FormikProvider value={formik} validator={() => ({})}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Card
                    sx={{
                      boxShadow: 'none',
                      border: '1px solid #D2D2D2',
                      borderRadius: '6px',
                      px: 4,
                      py: 1,
                      display: 'flex',
                      flexDirection: 'column'
                      //   justifyContent: 'center'
                    }}
                  >
                    <Typography sx={{ mt: 2, fontSize: '1.25rem', fontWeight: 'bold' }}>
                      Customer End of Year Rollover Transaction
                    </Typography>
                    <Grid container spacing={3} sx={{ width: '100%', py: 2 }}>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Investor Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('investor_name')}
                          error={Boolean(touched.investor_name && errors.investor_name)}
                          helperText={touched.investor_name && errors.investor_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Token</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"

                          {...getFieldProps('current_token_name')}
                          error={Boolean(touched.current_token_name && errors.current_token_name)}
                          helperText={touched.current_token_name && errors.current_token_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Current Holdings</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"

                          {...getFieldProps('current_holdings')}
                          error={Boolean(touched.current_holdings && errors.current_holdings)}
                          helperText={touched.current_holdings && errors.current_holdings}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Invested Amount</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('invested_amount')}
                          error={Boolean(touched.invested_amount && errors.invested_amount)}
                          helperText={touched.invested_amount && errors.invested_amount}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Investment Value</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('investment_value')}
                          error={Boolean(touched.investment_value && errors.investment_value)}
                          helperText={touched.investment_value && errors.investment_value}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Current Token Price</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('current_token_price')}
                          error={Boolean(touched.current_token_price && errors.current_token_price)}
                          helperText={touched.current_token_price && errors.current_token_price}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Profits/Loss</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('profit_or_loss_amount')}
                          error={Boolean(touched.profit_or_loss_amount && errors.profit_or_loss_amount)}
                          helperText={touched.profit_or_loss_amount && errors.profit_or_loss_amount}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Settlement tokens</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          InputProps={{ readOnly: true }}
                          //   placeholder="Enter Your Name"
                          {...getFieldProps('settlement_tokens')}
                          error={Boolean(touched.settlement_tokens && errors.settlement_tokens)}
                          helperText={touched.settlement_tokens && errors.settlement_tokens}
                        />
                      </Grid>
                      {!rolloverInfomation?.is_rollover_complete && (
                        <Grid item lg={6} md={12} xs={12}>
                          <FormLabel>Payment Receipt</FormLabel>

                          <TextField
                            sx={{
                              mt: 1.5
                            }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="file"
                            inputRef={proofDocument}
                            inputProps={{ accept: '.pdf' }}
                            {...getFieldProps('rollover_proof_document')}
                            id="rollover_proof_document"
                            error={Boolean(
                              touched.rollover_proof_document && errors.rollover_proof_document
                            )}
                            helperText={
                              touched.rollover_proof_document && errors.rollover_proof_document
                            }
                          />
                        </Grid>
                      )}
                      

                      {!rolloverInfomation?.is_rollover_complete && (
                        <Grid item lg={12} md={12} xs={12}>
                          <FormGroup
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center' // Use alignItems to vertically center the elements
                            }}
                          >
                            <FormControlLabel
                              sx={{ margin: 0, mr: 1 }}
                              control={<Checkbox />}
                              label=""
                              {...getFieldProps('is_proceed')}
                              error={Boolean(touched.is_proceed && errors.is_proceed)}
                              helperText={touched.is_proceed && errors.is_proceed}
                            />
                            <Typography
                              sx={{
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                //   color: 'blue',
                                //   textDecoration: 'underline',
                                fontFamily: 'BeVietnamPro'
                              }}
                              component="p"
                            >
                              Do you agree to processing this rollover?
                            </Typography>
                            {touched.is_proceed && errors.is_proceed && (
                              <FormHelperText sx={{ color: '#d32f2f', ml: 1.5 }}>
                                {errors.is_proceed}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                    {!rolloverInfomation?.is_rollover_complete && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          marginY: 2
                        }}
                      >
                        <Button
                          disabled={submitting}
                          variant="contained"
                          flex="end"
                          sx={{
                            marginRight: 2,
                            backgroundColor: '#000',
                            color: '#FFF'
                          }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <LoadingActionButton
                          variant="contained"
                          loadingPosition="start"
                          disabled={submitting}
                          sx={{
                            marginRight: 2,
                            backgroundColor: '#000',
                            color: '#FFF'
                          }}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {submitting ? `Processing` : `Process`}
                        </LoadingActionButton>
                      </Box>
                    )}
                  </Card>

                  <FocusError />
                </Form>
              </FormikProvider>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default RolloverModal;
