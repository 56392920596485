import Page from 'components/Page';
import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import Holding from 'components/masterData/Investor/Holding';
import OpenTransaction from 'components/masterData/Investor/OpenTransaction';
import InvestorIssuanceEscrowTable from 'components/subscritiption/InvestorIssuanceEscrowTable';
import InvestorRedemptionEscrowTable from 'components/subscritiption/InvestorRedemptionEscrowTable';
import InvestorViewAllotment from 'components/subscritiption/InvestorViewAllotment';
import InvestorViewRedemption from 'components/subscritiption/InvestorViewRedemptions';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import { ReactComponent as ExpandMoreIcon } from '../../assets/images/caratDown.svg';
import BTLChart from 'components/masterData/Investor/BTLChart';
import InvestorReportsDownload from 'components/subscritiption/InvestorReportsDownload';
import { useAuthStateShared, useSubscriptionState, useWeb3, useHttpApi, useAppState } from 'state';

const InvestorDashboard = () => {
  const { tokens, fetchTokens } = useSubscriptionState();
  const { user, setUserInvestmentBalance } = useAuthStateShared();
  const { refetchInvestorDetails } = useAppState();
  const { getCurrentUser } = useHttpApi();

  // STATES
  const [selectedToken, setSelectedToken] = useState('');
  const [refetchRedemption, setRefetchRedemption] = useState(false);

  useEffect(() => {
    setSelectedToken(tokens.at(-1));
  }, [tokens]);

  useEffect(() => {
    fetchTokens(`?investor_xinfin_address=${user?.xinfin_address?.xinfin_account_address ?? ''}`);
  }, []);

  return (
    <>
      <Page title="My Investments">
        <SubscriptionHead title="My Investments" />
        <Container>
          {/* <Breadcrumbs pageHead="My Investments" /> */}
          <Box sx={{ display: 'flex', justifyContent: 'start', mt: 3, width: '40%' }}>
            <FormControl fullWidth size="small">
              <InputLabel id="customer-select-label">Token</InputLabel>
              <Select
                fullWidth
                label="Token *"
                value={selectedToken}
                size="small"
                placeholder="Select Token"
                onChange={async (e) => {
                  console.log('🚀 ~ CustomerTransactionReport ~ e:', e);
                  setSelectedToken(e.target.value);
                  const { user } = await getCurrentUser();
                  setUserInvestmentBalance(user?.total_investments);
                }}
              >
                {tokens.length > 0 ? (
                  tokens.map((token) => {
                    return (
                      <MenuItem key={token.token_id} value={token}>
                        {token.token_name} | {token.token_symbol}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled>No Tokens Available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          {/** BTL Chart */}
          <Card sx={{ mt: 2, background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)` }}>
            <BTLChart currentToken={selectedToken} refetchDetails={refetchInvestorDetails} />
          </Card>

          {/** Open Transaction */}
          <Card sx={{ mt: 2 }}>
            <OpenTransaction currentToken={selectedToken} refetchDetails={refetchInvestorDetails} />
          </Card>

          {/* Holding/Allotments */}
          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Holdings
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewAllotment
                currentToken={selectedToken}
                refetchDetails={refetchInvestorDetails}
                setRefetchRedemption={setRefetchRedemption}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Redemptions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewRedemption
                currentToken={selectedToken}
                refetchDetails={refetchInvestorDetails}
                refetchRedemption={refetchRedemption}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Transaction Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorReportsDownload currentToken={selectedToken} />
            </AccordionDetails>
          </Accordion>
        </Container>
      </Page>
    </>
  );
};

export default InvestorDashboard;
