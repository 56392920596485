import { Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStateShared, useAppState } from 'state';

const MasterDataButton = ({totalTokensCreated}) => {
  const { pathname } = useLocation();
  const pathnames = pathname.split('/').filter((x) => x);
  const { role } = useAuthStateShared();
  const routeTo = pathnames[pathnames.length - 1];
  const navigate = useNavigate();
  const {tokenCreated} = useAppState();

  const { btnText, btnRoute, btnRole } = useMemo(() => {
    let _btnText = '';
    let _btnRoute = '';
    let _btnRole = '';
    switch (routeTo) {
      // Issuer Routes
      case 'add-issuer':
        _btnText = 'Add Issuer';
        _btnRoute = '/masterdata/issuer/add-issuer';
        _btnRole = 'OWNER';
        break;
      case 'issuer':
        _btnText = 'Add Issuer';
        _btnRoute = '/masterdata/issuer/add-issuer';
        _btnRole = 'OWNER';
        break;
      case 'edit-issuer':
        _btnText = 'Add Issuer';
        _btnRoute = '/masterdata/issuer/add-issuer';
        _btnRole = 'OWNER';
        break;
      case 'add-fund-manager':
        _btnText = 'Add Fund Manager';
        _btnRoute = '/masterdata/fund-manager/add-fund-manager';
        _btnRole = 'OWNER';
        break;
      case 'fund-manager':
        _btnText = 'Add Fund Manager';
        _btnRoute = '/masterdata/fund-manager/add-fund-manager';
        _btnRole = 'OWNER';
        break;
      // Investor Routes
      case 'add-investor':
        _btnText = 'Add Investor';
        _btnRoute = '/masterdata/investor/add-investor';
        _btnRole = 'PRIMARY_ISSUER';
        break;

      // case 'investor':
      //   _btnText = 'Add Investor';
      //   _btnRoute = '/masterdata/investor/add-investor';
      //   _btnRole = 'PRIMARY_ISSUER';
      //   break;

      case 'edit-investor':
        _btnText = 'Add Investor';
        _btnRoute = '/masterdata/investor/add-investor';
        _btnRole = 'PRIMARY_ISSUER';
        break;

      // Custodian Routes
      case 'add-custodian':
        _btnText = 'Add Custodian';
        _btnRoute = '/masterdata/custodian/add-custodian';
        _btnRole = 'OWNER';
        break;
      case 'custodian':
        _btnText = 'Add Custodian';
        _btnRoute = '/masterdata/custodian/add-custodian';
        _btnRole = 'OWNER';
        break;
      case 'edit-custodian':
        _btnText = 'Add Custodian';
        _btnRoute = '/masterdata/custodian/add-custodian';
        _btnRole = 'OWNER';
        break;

      // KYC PROVIDER ROUTES

      // case 'add-kyc-provider':
      //   _btnText = 'Add KYC Provider';
      //   _btnRoute = '/masterdata/kyc-provider/add-kyc-provider';
      //   _btnRole = 'OWNER';
      //   break;
      // case 'kyc-provider':
      //   _btnText = 'Add KYC Provider';
      //   _btnRoute = '/masterdata/kyc-provider/add-kyc-provider';
      //   _btnRole = 'OWNER';
      //   break;
      case 'edit-kyc-provider':
        _btnText = 'Add KYC Provider';
        _btnRoute = '/masterdata/kyc-provider/add-kyc-provider';
        _btnRole = 'OWNER';
        break;

      // Credit Insurer Routes
      case 'add-credit-insurer':
        _btnText = 'Add Credit Insurer';
        _btnRoute = '/masterdata/credit-insurer/add-credit-insurer';
        _btnRole = 'OWNER';
        break;
      case 'credit-insurer':
        _btnText = 'Add Credit Insurer';
        _btnRoute = '/masterdata/credit-insurer/add-credit-insurer';
        _btnRole = 'OWNER';
        break;
      case 'edit-credit-insurer':
        _btnText = 'Add Credit Insurer';
        _btnRoute = '/masterdata/credit-insurer/add-credit-insurer';
        _btnRole = 'OWNER';
        break;

      // Originator Routes
      case 'add-originator':
        _btnText = 'Add Originator';
        _btnRoute = '/masterdata/originator/add-originator';
        _btnRole = 'OWNER';
        break;
      case 'originator':
        _btnText = 'Add Originator';
        _btnRoute = '/masterdata/originator/add-originator';
        _btnRole = 'OWNER';
        break;
      case 'edit-originator':
        _btnText = 'Add Originator';
        _btnRoute = '/masterdata/originator/add-originator';
        _btnRole = 'OWNER';
        break;

      // Liquidity Pool Routes
      // case 'add-liquidity-pool':
      //   _btnText = 'Add Liquidity Pool';
      //   _btnRoute = '/masterdata/liquidity-pool/add-liquidity-pool';
      //   _btnRole = 'OWNER';
      //   break;
      // case 'liquidity-pool':
      //   _btnText = 'Add Liquidity Pool';
      //   _btnRoute = '/masterdata/liquidity-pool/add-liquidity-pool';
      //   _btnRole = 'OWNER';
      //   break;
      // case 'edit-liquidity-pool':
      //   _btnText = 'Add Liquidity Pool';
      //   _btnRoute = '/masterdata/liquidity-pool/add-liquidity-pool';
      //   _btnRole = 'OWNER';
      //   break;

      // // CryptoAsset Transaction Routes
      // case 'add-crypto-asset-txn':
      //   _btnText = 'Add Crypto Asset Transaction';
      //   _btnRoute = '/masterdata/crypto-asset-txn/add-crypto-asset-txn';
      //   break;
      // case 'crypto-asset-txn':
      //   _btnText = 'Add Crypto Asset Transaction';
      //   _btnRoute = '/masterdata/crypto-asset-txn/add-crypto-asset-txn';
      //   break;
      // case 'edit-crypto-asset-txn':
      //   _btnText = 'Add Crypto Asset Transaction';
      //   _btnRoute = '/masterdata/crypto-asset-txn/add-crypto-asset-txn';
      //   break;

      // Cash Transaction Routes
      case 'add-cash-txn':
        _btnText = 'Add Cash Transaction';
        _btnRoute = '/masterdata/cash-txn/add-cash-txn';
        break;
      case 'cash-txn':
        _btnText = 'Add Cash Transaction';
        _btnRoute = '/masterdata/cash-txn/add-cash-txn';
        break;
      case 'edit-cash-txn':
        _btnText = 'Add Cash Transaction';
        _btnRoute = '/masterdata/cash-txn/add-cash-txn';
        break;

      // bank routes
      case 'add-bank-account':
        _btnText = 'Add Bank Account';
        _btnRoute = '/masterdata/bank-account/add-bank-account';
        break;
      case 'bank-account':
        _btnText = 'Add Bank Account';
        _btnRoute = '/masterdata/bank-account/add-bank-account';
        break;
      case 'edit-bank-account':
        _btnText = 'Add Bank Account';
        _btnRoute = '/masterdata/bank-account/add-bank-account';
        break;

      // user routes
      case 'add-user':
        _btnText = 'Add User';
        _btnRoute = '/masterdata/user/add-user';
        _btnRole = ['OWNER', 'PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;
      case 'user':
        _btnText = 'Add User';
        _btnRoute = '/masterdata/user/add-user';
        _btnRole = ['OWNER', 'PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;
      case 'edit-user':
        _btnText = 'Add User';
        _btnRoute = '/masterdata/user/add-user';
        _btnRole = ['OWNER', 'PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;

      // Xinfin Routes
      case 'add-xinfin':
        _btnText = 'Add Xinfin';
        _btnRoute = '/masterdata/xinfin/add-xinfin';
        break;
      case 'xinfin':
        _btnText = 'Add Xinfin';
        _btnRoute = '/masterdata/xinfin/add-xinfin';
        break;
      case 'edit-xinfin':
        _btnText = 'Add Xinfin';
        _btnRoute = '/masterdata/xinfin/add-xinfin';
        break;

      // Collateral Txn Routes
      case 'collateral-txn':
        _btnText = 'Add Collateral Transaction';
        _btnRoute = '/masterdata/collateral-txn/add-collateral-txn';
        break;

      // Crypto Asset Routes
      case 'crypto-txn-payment':
        _btnText = 'Crypto Txn. Paymnent';
        _btnRoute = '/masterdata/crypto-asset-txn/crypto-txn-payment';
        _btnRole = ['PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;
      case 'crypto-asset-txn':
        _btnText = 'Crypto Txn. Paymnent';
        _btnRoute = '/masterdata/crypto-asset-txn/crypto-txn-payment';
        _btnRole = ['PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;
      case 'edit-crypto-asset-txn':
        _btnText = 'Crypto Txn. Paymnent';
        _btnRoute = '/masterdata/crypto-asset-txn/crypto-txn-payment';
        _btnRole = ['PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
        break;

      default:
        _btnText = 'Create New Token';
        _btnRoute = '/home/create-token';
        _btnRole = ['PRIMARY_ISSUER', 'ISSUER_OPERATOR'];
        break;
    }
    return {
      btnText: _btnText,
      btnRoute: _btnRoute,
      btnRole: _btnRole
    };
  }, [routeTo]);

  if (!btnRole.includes(role)) {
    return null;
  } 
  else if(routeTo ==="create-token"){
    return null;
  }
  
  else {
    return (
      <Button
        variant="gradient"
        // The below condition is added because we need to disable the Create New Token Button after 
        // one token is created. This is essentially a single token system now.
        // disabled={totalTokensCreated && totalTokensCreated>0 && btnText==="Create New Token"}
        // disabled={
        //   btnText === "Create New Token" &&
        //   ((totalTokensCreated ?? 0) > 0 || tokenCreated === true)
        // }
        onClick={() => {
          navigate(btnRoute);
        }}
      >
        {btnText}
      </Button>
    );
  }
};

export default MasterDataButton;
