import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Grid } from '@mui/material';
import { useAppState, useWeb3, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import LoadingActionButton from 'helpers/LoadingActionButton';

const RedeemTokenModal = ({
  open,
  handleClose: handleCloseModal,
  value,
  platformFees,
  maxTokens,
  allotmentId,
  issuerAddress,
  deploymentAddress,
  redeemRequestedQty,
  allRedeemRequestedQty,
  refreshTable,
  setRefetchRedemption
}) => {
  const { giveRedeemAllowance, checkAllowance } = useWeb3();
  const [quantity, setQuantity] = useState('');
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const { createBTLRedemption } = useHttpApi();

  const handleClose = () => {
    setQuantity('');
    setError('');
    setIsSubmitting(false);
    handleCloseModal();
  };
  const handleQuantityChange = (e) => {
    const enteredQuantity = parseInt(e.target.value, 10);

    // Prevent user from entering values greater than maxQuantity
    if (enteredQuantity > maxTokens) {
      setError(`Quantity cannot exceed ${maxTokens}`);
    } else {
      setQuantity(enteredQuantity);
      setError(''); // Clear error if within valid range
    }
  };

  // Calculate amount and finalAmount dynamically based on quantity
  const amount = quantity > 0 ? quantity * value : 0;
  const platformFeesValue = (platformFees / 100) * amount;
  const finalAmount = amount - platformFeesValue;

  const setQuantityAndClose = () => {
    setQuantity(0);
    handleClose();
  };

  return (
    <Modal open={open} onClose={setQuantityAndClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 600,
          p: 4,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Redeem Token
        </Typography>

        {/* Quantity Input */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Previously Redeem Requested Qty"
              value={redeemRequestedQty}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={quantity}
              onChange={handleQuantityChange}
              error={Boolean(error)}
              helperText={error}
              InputProps={{
                inputProps: { min: 0, max: maxTokens } // Enforces max and min constraints
              }}
            />
          </Grid>

          {/* Static fields */}
          <Grid item xs={12}>
            <TextField label="Value" value={value} fullWidth InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Platform Fees (%)"
              value={platformFees}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>

          {/* Dynamically calculated fields */}
          <Grid item xs={12}>
            <TextField
              label="Amount"
              value={amount.toFixed(2)}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Final Amount"
              value={finalAmount.toFixed(2)}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>

        <LoadingActionButton
          autoFocus
          loadingPosition="start"
          loading={isSubmitting}
          variant="contained"
          sx={{ backgroundColor: '#007BFF', width: '7.75rem', height: '3.125rem', mt: 2 }}
          onClick={async () => {
            if (!quantity) {
              setError(`Quantity cannot be empty.`);
              return;
            }
            setIsSubmitting(true);
            try {
              const _issuerAllowance = await checkAllowance(deploymentAddress, issuerAddress);
              const _requiredAllowance = Number(allRedeemRequestedQty) + Number(quantity);
              if (Number(_issuerAllowance) < _requiredAllowance) {
                await giveRedeemAllowance(deploymentAddress, issuerAddress, _requiredAllowance);
              }
              handleClose();
              // Add the api call to insert record into the Redemption Table
              const postData = {
                redeemQty: quantity,
                allotmentId
              };
              const redeemResponse = await createBTLRedemption(postData);
              if (redeemResponse.status === 'success') {
                enqueueSnackbar('Redeem request submitted successfully', {
                  variant: 'success'
                });
              }
              refreshTable();
              setRefetchRedemption((state) => !state);
              setQuantity(0);
              navigate('/subscription/investor-dashboard');

              setIsSubmitting(false);
            } catch (e) {
              console.log(e);
              throwErrorMessage(e);
              setIsSubmitting(false);
              setQuantity(0);
              handleClose();
            }
          }}
        >
          Redeem
        </LoadingActionButton>
      </Box>
    </Modal>
  );
};

export default RedeemTokenModal;
