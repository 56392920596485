import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import constate from 'constate';

import { userTokenPersistence } from 'persistence';

import { LOGIN_URL } from 'config';

const useAuthStateShared_ = () => {
  const [user, setUser] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [role, setRole] = useState();
  const [entity, setEntity] = useState();
  const [userInvestmentBalance, setUserInvestmentBalance] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (isReady && !isAuthorized) {
      navigate(LOGIN_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isAuthorized]);

  const setLoggedOutState = useCallback(() => {
    userTokenPersistence.clear();
    setUser(null);
    setUserInvestmentBalance(0);
    setIsAuthorized(false);
    setRole(null);
  }, []);

  return {
    user,
    setUser,
    setLoggedOutState,
    setIsReady,
    setIsAuthorized,
    isReady,
    isAuthorized,
    setOrgLogo,
    orgLogo,
    role,
    setRole,
    entity,
    setEntity,
    userInvestmentBalance,
    setUserInvestmentBalance
  };
};

export const [AuthStateSharedProvider, useAuthStateShared, useSetLoggedOutState, useUser] =
  constate(
    useAuthStateShared_,
    (state) => state,
    ({ setLoggedOutState }) => setLoggedOutState,
    ({ user }) => user
  );
