import {
  AppBar,
  Container,
  Toolbar,
  Button,
  Box,
  IconButton,
  Badge,
  Popover,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import WalletNavbar from 'components/WalletNavbar';
import dashboard from '../assets/images/dashboard.svg';
import ownerDashboard from '../assets/images/ownerDashboard.svg';
import { useNavigate } from 'react-router';
import { useCoreTableState, useAppState, useAuthStateShared } from 'state';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Notification from './masterData/Notification';
import InvestorNotifications from './InvestorNotifications';
import AccountPopover from 'layouts/main/AccountPopover';
import DashboardIcon from '../assets/images/dashboard_icon.svg';
import WalletIcon from '../assets/images/wallet_icon.svg';
import MyInvestmentIcon from '../assets/images/my_investment_icon.svg';

const SubscriptionNavbar = () => {
  const { userInvestmentBalance, isInvestor } = useAuthStateShared();
  // console.log('user here- - - -- ', user);
  const { fetchOrganizations, orgnizations } = useCoreTableState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, fetchUserNotifications } = useAppState();
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#0C0B22',
        height: '4.3rem'
      }}
    >
      <Toolbar>
        <Box
          sx={{
            // width: '11.1rem',
            flex: 1,
            display: 'flex',
            ml: 7
          }}
        >
          {orgnizations[0] && (
            <Box
              component="img"
              src={orgnizations[0].logo}
              sx={{ height: '2.9rem', cursor: 'pointer' }}
              alt="Tokenisation Logo"
              onClick={() => navigate('/subscription')}
            />
          )}
        </Box>

        {/* <Box sx={{ flexGrow: 1.5 }} /> */}
        {/* <WalletNavbar /> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        <Box
          sx={{
            alignItems: 'center',
            flex: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 3
            }}
          >
            <Button
              onClick={() => navigate('/subscription')}
              sx={{
                color: location.pathname === '/subscription' ? '#0C0B22' : '#f5f8fa',
                backgroundColor: location.pathname === '/subscription' ? '#f5f8fa' : '#0C0B22',
                mr: 2,
                '&:hover': {
                  color: location.pathname === '/subscription' ? '#0C0B22' : '#f5f8fa',
                  backgroundColor: location.pathname === '/subscription' ? '#f5f8fa' : '#0C0B22'
                }
              }}
            >
              <Box sx={{ mr: 1 }} component="img" src={DashboardIcon} alt="dashboard  icon" /> Token
              Dashboard
            </Button>
            <Button
              onClick={() => navigate('/subscription/investor-dashboard')}
              sx={{
                color:
                  location.pathname === '/subscription/investor-dashboard' ? '#0C0B22' : '#f5f8fa',
                mr: 1,
                backgroundColor:
                  location.pathname === '/subscription/investor-dashboard' ? '#f5f8fa' : '#0C0B22',
                '&:hover': {
                  color:
                    location.pathname === '/subscription/investor-dashboard'
                      ? '#0C0B22'
                      : '#f5f8fa',
                  backgroundColor:
                    location.pathname === '/subscription/investor-dashboard' ? '#f5f8fa' : '#0C0B22'
                }
              }}
            >
              <Box sx={{ mr: 1 }} component="img" src={WalletIcon} alt="investments  icon" /> My
              Wallet
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              direction: 'row',
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Button
              sx={{
                maxHeight: '0px',
                display: 'flex',
                direction: 'row',
                justifyContent: 'flex-end',
                fontSize: '14px',
                backgroundColor: '#162AA1',
                ml: 20,
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#162AA1'
                },
                pointerEvents: 'none'
              }}
            >
              ${userInvestmentBalance}
            </Button>
          </Box>
        </Box>

        <Box sx={{ flex: 1, justifyContent: 'flex-end', display: 'flex', mr: 7 }}>
          <IconButton sx={{ mx: 1 }} onClick={handleClick}>
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsActiveIcon color="primary" />
            </Badge>
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <InvestorNotifications />
          </Popover>

          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SubscriptionNavbar;
