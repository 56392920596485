import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from 'recharts';
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';

import btlLogo from '../../../assets/images/bettor-token-logo.png';
import { useAppState, useHttpApi } from 'state';
import SyncIcon from '@mui/icons-material/Sync';
import { difference } from 'lodash';

const ChartSummaryLabel = ({ summary, finalPL }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(value);
  };

  return (
    <Box
      sx={{
        minWidth: { md: '48%' },
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        marginTop: {
          xs: 1
        },
        justifyContent: {
          xs: 'center', // Center align items for small screens
          md: 'start' // Align items to the bottom for medium screens and above
        },

        alignItems: 'end'
      }}
    >
      <Box
        component="img"
        src={btlLogo}
        alt="Logo"
        sx={{
          width: '80px'
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>Invested Amount: </Typography>
          <Typography variant="h5" sx={{ fontFamily: 'Manrope', ml: 1 }}>
            {formatCurrency(summary?.total_investment ?? 0)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>Token Price: </Typography>
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Manrope',
              display: 'inline-flex',
              flexDirection: 'row',
              ml: 1
            }}
          >
            {`$${summary?.token_nav ?? 0}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>P&L: </Typography>
          <Typography
            variant="h5"
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              fontFamily: 'Manrope',
              color: `${finalPL < 0 ? 'red' : '#5E974F'}`,
              ml: 1
            }}
          >
            {'  '} {`( ${finalPL < 0 ? '-' : '+'}`}
            {`${formatCurrency(Math.abs(finalPL ?? 0) ?? 0)} )`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ChartRangeSelect = ({ setDateRange, rangeValue }) => {
  // Define button labels and their corresponding values
  const buttons = [
    { label: '1D', value: '1D' },
    { label: '1W', value: '1W' },
    { label: '1M', value: '1M' },
    { label: '3M', value: '3M' },
    { label: '6M', value: '6M' },
    { label: '1Y', value: '1Y' }
  ];

  // Function to get styles based on selected value
  const getButtonStyles = (value) => ({
    fontFamily: rangeValue === value ? 'Cy Extrabold' : 'Cy Semibold',
    color: rangeValue === value ? '#5B9CFF' : 'black'
  });

  return (
    <Box
      sx={{
        backgroundColor: '',
        width: '100%',
        maxWidth: {
          md: '48%'
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: {
          sm: 'center',
          xs: 'center',
          md: 'end'
        }
      }}
    >
      {buttons.map(({ label, value }) => (
        <Button
          key={value}
          onClick={() => setDateRange(value)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <Typography sx={getButtonStyles(value)}>{label}</Typography>
        </Button>
      ))}
    </Box>
  );
};
const BTLChart = ({ currentToken, refetchDetails }) => {
  const {
    getBettorTokenNavHistory,
    getTokenInvestorSummary,
    getTokenNavHistory,
    getProftOrLossInTimeInterval
  } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  const dateISOFormat = (date) => {
    return date.toISOString().split('T')[0];
  };

  const today = new Date();
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(dateISOFormat(today));
  const queryParams = `?from=${fromDate || ''}&to=${toDate || ''}`;
  const [range, setRange] = useState('1D');
  const temporaryFromDate = today;
  const [summaryData, setSummaryData] = useState({});
  const [finalPL, setFinalPL] = useState(0);

  const getDateRange = (value) => {
    switch (value) {
      case '1D':
        temporaryFromDate.setDate(today.getDate() - 1);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      case '1W':
        temporaryFromDate.setDate(today.getDate() - 7);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      case '1M':
        temporaryFromDate.setMonth(today.getMonth() - 1);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      case '3M':
        temporaryFromDate.setMonth(today.getMonth() - 3);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      case '6M':
        temporaryFromDate.setMonth(today.getMonth() - 6);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      case '1Y':
        temporaryFromDate.setFullYear(today.getFullYear() - 1);
        setFromDate(dateISOFormat(temporaryFromDate));
        break;
      default:
        break;
    }
  };

  const fetchNavHistory = async () => {
    try {
      const res = await getTokenNavHistory(currentToken?.token_id, queryParams);
      // const res = await getBettorTokenNavHistory(queryParams);
      setData(res.reverse());
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  const fetchSummary = async () => {
    try {
      const res = await getTokenInvestorSummary(currentToken?.token_id);
      setSummaryData(res);
      const pLResposne = await getProftOrLossInTimeInterval(fromDate, currentToken?.token_id);
      console.log('final PL -', pLResposne);
      setFinalPL(pLResposne.final_p_l);
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  useEffect(() => {
    getDateRange(range);
  }, [range]);

  useEffect(() => {
    if (fromDate) {
      fetchNavHistory();
      fetchSummary();
    }
  }, [fromDate, currentToken?.token_id, refetchDetails]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}
        >
          <p>Date: {payload[0].payload.date}</p>
          <p>Price: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card
      sx={{ width: '100%', background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`, px: 4 }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
          <Typography variant="h3">Your {currentToken?.token_name} </Typography>
          <IconButton
            sx={{ ml: 1 }}
            onClick={() => {
              fetchNavHistory();
              fetchSummary();
            }}
          >
            <SyncIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 100,
            backgroundColor: '',
            mb: 0,
            mt: 5
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width="100%"
              height="100%"
              data={data}
              margin={{ top: 30, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend verticalAlign="top" align="right" height={36} /> */}

              <Line type="monotone" dot={false} dataKey="NAV" stroke="#5B9CFF" />
            </LineChart>
          </ResponsiveContainer>
          {/* Chart Footer */}
        </Box>

        <Box
          sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: {
              xs: 'column-reverse', // Stack items vertically for small screens and below
              md: 'row' // Horizontal layout for medium screens and above
            },
            justifyContent: {
              xs: 'start',
              md: 'space-between'
            },
            alignItems: {
              xs: 'center',
              md: 'end'
            }
          }}
        >
          <ChartSummaryLabel summary={summaryData} finalPL={finalPL} />

          <ChartRangeSelect setDateRange={setRange} rangeValue={range} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BTLChart;
